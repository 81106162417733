<template>
  <div class="w-100">
    <div @click="handleShowSidebar" class="mt-3 mb-3">
      <b-button v-if="isMobile" variant="transparent" class="float-left mt-n1 d-inline-block">
        <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
      </b-button>

      <h5 class="d-inline-block">{{ "New Task" | __t("projects__task__headline__new-task") }}</h5>
    </div>

    <spotlight v-if="!isSending" @action="triggerCreateTask" :commands="TASKS_OPTIONS_COMMAND" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE } from "@/store/actions";

import { BButton } from "bootstrap-vue";
import cloneDeep from "lodash/cloneDeep";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { DEFAULT_FIRST_MESSAGE, DEFAULT_REPLY, TASKS_OPTIONS_COMMAND } from "@/constants/tasks";

import { getTranslation } from "@/filters/translation";
import { toSlug } from "@/helpers/utils";

import Spotlight from "@/components/Spotlight/";

export default {
  name: "TaskWizard",
  components: { BButton, Spotlight },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    isSending: false,

    TASKS_OPTIONS_COMMAND,
  }),
  methods: {
    ...mapActions("projectsTasks", { createTask: CREATE }),
    ...mapActions("projectsTasksDiscussions", { createMessage: CREATE }),
    async triggerCreateTask(actionId) {
      this.isSending = true;

      const { projectId } = this;
      const { id: taskId } = await this.createTask({ projectId, actionId });

      const role = CHATBOT_ROLES.ASSISTANT;
      const format = CHATBOT_MESSAGE_TYPE.TEXT;
      const content = DEFAULT_FIRST_MESSAGE[actionId]
        ? getTranslation(
            cloneDeep(DEFAULT_FIRST_MESSAGE[actionId]),
            toSlug(`first-message__${cloneDeep(DEFAULT_FIRST_MESSAGE[actionId])}`)
          )
        : null;
      const reply = { format: DEFAULT_REPLY[actionId]?.format || null };

      await this.createMessage({ projectId, taskId, content, role, format, reply });

      this.$router.push({ name: "ProjectTask", params: { taskId } });

      this.isSending = false;
    },

    handleShowSidebar() {
      this.$bus.emit("show-sidebar");
    },
  },
};
</script>
